
.el-form-item[data-v-161c55f6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.form-item[data-v-161c55f6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
[data-v-161c55f6] .tableSclect .el-input--small .el-input__inner {
  height: 22px;
  line-height: 22px;
}
[data-v-161c55f6] .tableSclect .el-input__suffix {
  top: 3px !important;
}
[data-v-161c55f6] .seltAllbtnDis .cell {
  visibility: hidden;
}
.aa[data-v-161c55f6].el-form-item__content {
  margin-left: 0px !important;
}
